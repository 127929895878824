<template>
  <div class="home-head">
    <div class="home-nav">
      <span @click="$router.push('/')">
        <img src="@/assets/img/logo.png" alt="logo" />
      </span>
      <div class="home-nav-list">
        <!--          <a  @click="goModel('/activity','0')" >热门活动</a>-->
        <a @click="$router.push('/activity')">热门活动</a>
        <!--          <router-link
              to="/activity"
              active-class="active"
          >热门活动</router-link>-->

        <a
          :class="{ red: centerShow }"
          @mouseenter="centerShowShowBtn(true)"
          @mouseleave="centerShowShowBtn(false)"
          >产品</a
        >

        <!--        <div
            @mouseenter="centerShowShowBtn(true)"
            @mouseleave="centerShowShowBtn(false)"
            @click="centerShow=false"
        >
          <router-link
              :class="{'red':centerShow}"
              to="/activity"
              active-class="active"
          >产品</router-link>
        </div>-->

        <a
          :class="{ red: connectShow }"
          @mouseenter="connectShowBtn(true)"
          @mouseleave="connectShowBtn(false)"
          >解决方案</a
        >
        <router-link
          :class="{ red: centerShow }"
          tag="a"
          to="/shiXun"
          active-class="active"
          >仿真实训</router-link
        >
        <router-link
          :class="{ red: centerShow }"
          tag="a"
          to="/gitIot"
          active-class="active"
          >GitloT</router-link
        >
      </div>
    </div>
    <div class="home-login">
      <a-dropdown overlay-class-name="head-area-dropdown">
        <span style="height: 58px; display: flex; align-items: center"
          >全国站 <a-icon style="margin-left: 4px" type="down"
        /></span>
        <a-menu slot="overlay">
          <a-menu-item key="1">
            <div class="head-area-box">
              <div>
                <p>区域站</p>
                <div>
                  <span class="active">全国站</span>
                  <span @click="toUrl('http://zh.gitok.com')">珠海站</span>
                  <span @click="toUrl('http://zs.gitok.com')">中山站</span>
                  <span @click="toUrl('http://wz.gitok.com')">乌镇站</span>
                  <span @click="toUrl('http://yc.gitok.com')">盐城站</span>
                  <span @click="toUrl('http://zz.gitok.com')">郑州站</span>
                  <span @click="toUrl('http://tj.gitok.com')">天津站</span>
                  <span @click="toUrl('http://szgt.gitok.com')"
                    >苏州高铁站</span
                  >
                </div>
              </div>
              <!-- <div>
                <p>功能站</p>
                <div>
                  <span>苏州车联网</span>
                </div>
              </div> -->
            </div>
          </a-menu-item>
        </a-menu>
      </a-dropdown>
      <span @click="toCon">控制台</span>
      <template v-if="!userInfo">
        <span @click="() => $router.push('/login')">登录</span>
        <span @click="() => $router.push('/register')">免费注册</span>
      </template>
      <template v-else>
        <a-dropdown
          overlay-class-name="dropdown-user-info"
          placement="bottomCenter"
        >
          <span style="height: 58px; display: flex; align-items: center"
            ><img src="@/assets/img/avatar.png" alt="avatar" width="32px"
          /></span>
          <a-menu slot="overlay">
            <a-menu-item>
              <p @click="toTab" class="layout-header-user-btn quit-login">
                基本信息
              </p>
            </a-menu-item>
            <a-menu-item key="1">
              <!--              <p @click="toTab" style="width: 100px;border-bottom: 1px solid #dedede;margin-bottom: 2px">
                用户信息
              </p>-->
              <p
                @click="$router.push('/logout')"
                class="quit-login layout-header-user-btn"
              >
                <a-icon type="import" style="margin-right: 4px" />
                退出
              </p>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </template>
      <p><img src="@/assets/img/dh.png" alt="dh" />400-025-6877</p>
    </div>

    <!--    解决方案-->
    <div
      v-show="connectShow"
      class="menu-head"
      @mouseenter="connectShowBtn(true)"
      @mouseleave="connectShowBtn(false)"
    >
      <menu-card
        :val="service"
        :show="connectShow"
        @change="showchange"
        @searchange="showchange"
        @btnchange="showchange"
      >
        <template #solution="slotProp">
          <program-card :sel="slotProp.id" @change="showchange"></program-card>
        </template>
      </menu-card>
    </div>

    <!--    产品-->
    <div
      v-show="centerShow"
      class="menu-head"
      @mouseenter="centerShowShowBtn(true)"
      @mouseleave="centerShowShowBtn(false)"
    >
      <menu-card
        :val="product"
        :show="centerShow"
        @change="centerShowchange"
        @btnchange="centerShowchange"
        @searchange="centerShowchange"
      >
        <template #product="slotProp">
          <hardware
            v-show="slotProp.id === '1500000000000000003'"
            @change="centerShowchange"
          ></hardware>
          <platformApply
            v-show="slotProp.id === '1500000000000000001'"
            @change="centerShowchange"
          ></platformApply>
        </template>
      </menu-card>
    </div>
  </div>
</template>

<script>
let isShow = false;
let solution = false;
import menu_card from "./components/menu_card";
import program_card from "./components/program_card";
import hardware from "./components/hardware";
import platformApply from "./components/platformApply";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      active: "",
      connectShow: false,
      centerShow: false,
      product: [
        { title: "硬件产品", id: "1500000000000000003" },
        { title: "平台应用", id: "1500000000000000001" },
      ],
      service: [
        { title: "工业场景解决方案", id: "1500000000000000003", index: "3" },
        { title: "消费场景解决方案", id: "1500000000000000001", index: "1" },
        { title: "政府场景解决方案", id: "1500000000000000002", index: "2" },
      ],
    };
  },
  computed: {
    ...mapGetters(["userInfo", "isLogin"]),
  },
  components: {
    "menu-card": menu_card,
    "program-card": program_card,
    hardware,
    platformApply,
  },
  methods: {
    toCon() {
      window.open(`/account`, "_blank");
    },
    toUrl(url) {
      window.open(url, "_blank");
    },
    toTab() {
      window.open(`/account/userLayout/userInfo`, "_blank");
    },
    setFloorNavMountClick(id) {
      document.getElementById(id).scrollIntoView({
        behavior: "smooth",
      });
    },
    showchange(state) {
      this.connectShow = state;
    },
    centerShowchange(state) {
      this.centerShow = state;
    },

    connectShowBtn(is) {
      solution = is;
      if (!is) {
        setTimeout(() => {
          this.connectShow = solution;
        }, 100);
      } else {
        this.connectShow = is;
      }
    },

    centerShowShowBtn(is) {
      isShow = is;
      if (!is) {
        setTimeout(() => {
          this.centerShow = isShow;
        }, 100);
      } else {
        this.centerShow = is;
      }
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.sub {
  width: 100vw;
}

.layout-header-user-btn {
  margin-bottom: 0;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: #fff;
  justify-content: center;
}

.quit-text {
  justify-content: center;
  height: 40px;
  line-height: 40px;
}

.quit-login {
  justify-content: center;
  height: 46px;
  line-height: 46px;
}

.home-head {
  background: #fff;
  padding-left: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);

  .menu-head {
    width: 100%;
    position: absolute;
    top: 64px;
    left: 0;
    z-index: 110;
  }

  .home-nav {
    > span {
      cursor: pointer;
      height: 32px;
      display: flex;
      align-items: center;
      margin-right: 56px;

      > img {
        max-height: 100%;
      }
    }

    display: flex;
    align-items: center;

    .home-nav-list {
      display: flex;

      a {
        display: block;
        color: #262626;
        margin-right: 30px;
        height: 64px;
        line-height: 64px;
        border-bottom: 2px solid transparent;
        cursor: pointer;

        &.active {
          border-color: #ea0b06;
          color: #ea0b06;
          border-bottom: 2px solid #ea0b06;
        }

        &:hover {
          border-color: #ea0b06;
          color: #ea0b06;
          border-bottom: 2px solid #ea0b06;
        }
      }

      .red {
        border-color: #ea0b06;
        color: #ea0b06;
        border-bottom: 2px solid #ea0b06;
      }
    }

    .home-nav-search {
      position: absolute;
      right: -80px;
    }
  }

  .home-login {
    display: flex;
    align-items: center;
    font-size: 14px;

    > span {
      cursor: pointer;
      margin-right: 24px;

      &:hover {
        color: #ea0b06;
      }
    }

    > p {
      background: #ea0b06;
      padding: 0 24px;
      height: 64px;
      color: #fff;
      display: flex;
      align-items: center;

      > img {
        width: 24px;
        height: 24px;
        margin-right: 8px;
      }
    }
  }

  .layout-header-user-info {
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 64px;
    margin-right: 24px;

    > span {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 10px;

      > img {
        display: block;
        height: 100%;
        height: 100%;
      }
    }

    > i {
      color: #262626;
    }
  }
}

.layout-header-user-btn {
  margin-bottom: 0;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: #fff;
  justify-content: space-between;

  > span {
    font-size: 12px;
    cursor: pointer;

    &.drz {
      color: #faad14;
    }

    &.yrz {
      color: #52c41a;
    }

    &.wtg {
      color: #b93838;
    }
  }
}

.quit-login {
  justify-content: center;
  height: 46px;
  line-height: 46px;
}

.home-nav-search-tag {
  width: 308px;

  > div {
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }

    > p {
      font-size: 14px;
      color: #8c8c8c;
      line-height: 12px;
      margin-bottom: 8px;
    }

    > div {
      display: flex;
      flex-wrap: wrap;

      > span {
        height: 32px;
        background: #ffffff;
        border: 1px solid #d9d9d9;
        padding: 0 16px;
        font-size: 12px;
        color: #262626;
        line-height: 30px;
        margin: 8px 8px 0 0;
        cursor: pointer;
      }
    }
  }
}
</style>
<style>
.home-nav-list-anchor {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
}

.home-nav-list-anchor .ant-anchor-ink {
  display: none;
}

.home-nav-list-anchor > div {
  display: flex;
}

.home-nav-list-anchor > div .ant-anchor-link {
  padding: 0;
  margin-right: 30px;
}

.home-nav-list-anchor > div .ant-anchor-link > a {
  color: #262626;
}

.head-area-box > div {
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
}

.home-nav-list-anchor .ant-anchor-link > a:hover {
  color: #ea0b06;
}

.head-area-box > div:last-child {
  margin-bottom: 0;
  border: 0;
  padding-bottom: 0;
}

.head-area-box > div > p {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: bold;
  line-height: 20px;
}

.head-area-box > div > div {
  display: flex;
  flex-wrap: wrap;
}

.head-area-box > div > div > span {
  width: 50%;
  line-height: 20px;
  font-size: 14px;
  margin-top: 8px;
  color: rgba(0, 0, 0, 0.65);
}

.head-area-box > div > div > span:hover {
  color: #ea0b06;
}

.head-area-box > div > div > span.active {
  color: #ea0b06;
}

.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
  background-color: transparent;
}

.head-area-dropdown .ant-dropdown-menu {
  padding: 16px 24px;
  width: 232px;
  box-shadow: 0 8px 13px 0 rgba(0, 0, 0, 0.07);
  border-radius: 0;
}

.head-area-dropdown .ant-dropdown-menu-item {
  padding: 0;
}

.home-login .ant-dropdown-open > i {
  transition: all 0.3s;
}

.home-login .ant-dropdown-open > i {
  transform: scale(0.83333333) rotate(180deg) !important;
}

.dropdown-user-info {
  /* width: 200px; */
  width: 170px;
  background: #2f2f2f;
}

.dropdown-user-info .ant-dropdown-menu {
  background-color: transparent;
}

.dropdown-user-info .ant-dropdown-menu-item,
.dropdown-user-info .ant-dropdown-menu-submenu-title {
  padding: 5px 16px;
}

.dropdown-user-info .ant-dropdown-menu-item:last-child {
  border-top: 1px solid rgba(0, 0, 0, 0.16);
}

.dropdown-user-info .ant-dropdown-menu-item:hover,
.dropdown-user-info .ant-dropdown-menu-submenu-title:hover {
  background-color: rgba(255, 255, 255, 0.08);
}
</style>
