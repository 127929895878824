import { PortalRequest as request } from "@/utils/request";
import storage from "store";
import { ACCESS_TOKEN } from "@/store/mutation-types";
import store from "@/store";

export const getTokenByCode = (data) => {
  return request({
    url: "/auth/accessToken",
    method: "get",
    params: data,
  });
};

/**
 * 获取当前登录用户基本信息
 */
export const getUserInfo = () => {
  // const token = storage.get(ACCESS_TOKEN);
  return request({
    url: "/res-user/me",
    method: "get",
  });
};

export const demoRequest = () => {
  return request({
    url: "http://localhost:9309/cas/common/sessions",
    method: "get",
  });
};
