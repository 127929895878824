<template>
  <div class="shi-xun-system">
    <h2>物联网实训系统组成</h2>
    <p>物联网底层硬件构成、业务原理及应用开发实训环境</p>
    <div>
      <div class="shi-xun-system-tag">
        <p :class="{ active: systemTag == 1 }" @click="systemTag = 1">
          设备开发工作站
        </p>
        <p :class="{ active: systemTag == 2 }" @click="systemTag = 2">
          应用开发工作站
        </p>
        <p :class="{ active: systemTag == 3 }" @click="systemTag = 3">
          低代码应用开发
        </p>
        <p :class="{ active: systemTag == 4 }" @click="systemTag = 4">
          开发板套件
        </p>
      </div>
      <div
        class="system-content-box"
        :class="{ 'content-box-change': systemTag == 4 }"
      >
        <div class="shi-xun-system-content">
          <div class="shi-xun-system-content-title">
            <p>IoT物联网开发板套件—LYEVK-3681A</p>
            <span
              >基于GitIoT国产智联网开发应用中枢低成本赋能智能产品技术创新和用户体验，实训工作站集成物联网硬件产品接入和管理，采用可视化应用搭建和通过编排服务节点的方式快速完成物联网底层业务逻辑的设计与开发</span
            >
          </div>
          <div class="shi-xun-system-equipment" v-if="systemTag == 1">
            <div>
              <p>设备开发工作站</p>
              <span
                >通过整合平台的基础能力，为IoT设备厂商提供一站式的设备开发能力。使用无缝衔接的开发及部署能力，构建将硬件与软件解耦的开发架构和实施架构，并支持IoT设备与云端无缝交互,大幅缩短围绕IoT设备及其关联软件的开发周期。</span
              >
              <a-button class="btn-red" size="large" @click="toShiXun(1)"
                >了解详情</a-button
              >
            </div>
            <img src="@/assets/img/system1.jpg" />
          </div>
          <div class="shi-xun-system-equipment" v-else-if="systemTag == 2">
            <div>
              <p>应用开发工作站</p>
              <span
                >为IoT解决方案商集成业务应用开发，通过平台的开放能力快速集成海量传感器和智能网关，利用零代码应用开发，持续集成和持续部署(CI/CD)工具及框架、开发部署新功能的容器方法，一站式满足项目方案实施。</span
              >
              <a-button class="btn-red" size="large" @click="toShiXun(2)"
                >了解详情</a-button
              >
            </div>
            <img src="@/assets/img/system2.jpg" />
          </div>
          <div class="shi-xun-system-equipment" v-else-if="systemTag == 3">
            <div>
              <p>低代码应用开发</p>
              <span
                >在设备和用户之间建立开箱即用的云平台和云应用服务，实现设备数据业务处理和开放API，提供Web、iOS、Android、小程序、H5多端应用组态开发和图形界面操作，业务流程化表达，微服务生态护航，帮助用户构建灵活强大的物联网应用。</span
              >
              <a-button class="btn-red" size="large" @click="toShiXun(3)"
                >了解详情</a-button
              >
            </div>
            <img src="@/assets/img/system3.jpg" />
          </div>
        </div>
        <div
          class="shi-xun-system-dev"
          :class="{ 'system-dev-bottom': systemdevNav === 2 }"
          v-show="systemTag == 4"
        >
          <div class="xun-system-nav">
            <div
              :class="{ 'nav-active': systemdevNav === 1 }"
              @click="systemdevNav = 1"
            >
              主要参数
            </div>
            <div
              :class="{ 'nav-active': systemdevNav === 2 }"
              @click="systemdevNav = 2"
            >
              产品介绍
            </div>
          </div>
          <div class="system-nav-one" v-show="systemdevNav === 1">
            <div class="shi-xun-system-dev-thead">
              <span>名称</span>
              <p>参数描述</p>
            </div>
            <div
              v-for="(e, i) in systemDev"
              :key="i"
              class="shi-xun-system-dev-tbody"
            >
              <span>{{ e.title }}</span>
              <p>{{ e.desc }}</p>
            </div>
          </div>
          <div class="system-nav-two" v-show="systemdevNav === 2">
            <div
              v-for="(el, i) in development"
              :key="i"
              class="development-item"
            >
              <p class="title">{{ el.title }}</p>
              <img :src="el.img" alt="" />
              <div
                v-for="(item, j) in el.info"
                :key="j"
                class="development-info"
              >
                <img src="@/assets/img/development/select.png" alt="" />
                <p>{{ item }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import request from "../../utils/request";
import { getDefaultCompany } from "@/api/res-user";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      systemTag: 1,
      systemdevNav: 1,
      systemDev: [
        {
          title: "SOC",
          desc: "海思Hi3861V100高性能集成芯片组，高性能 32bit 微处理器，最大工作频率 160MHz，内嵌 SRAM 352KB、ROM 288KB，内嵌 2MB Flash",
        },
        {
          title: "操作系统",
          desc: "支持HUAWEI LiteOS和第三方组件，并配套提供开放、易用的开发和调试运行环境，支持鸿蒙多种特性，联接多种生态",
        },
        {
          title: "组网能力",
          desc: "支持 STA 和 AP 形态，作为 AP 时最大支持 6 个 STA 接入网状mesh组网能力，连接节点数200+",
        },
        {
          title: "安全可靠",
          desc: "支持 WFA WPA/WPA2 personal、WPS2.0。支持 QoS，满足不同业务服务质量需求。支持STBC空时分组码编码和Short-GI速率提升技术",
        },
        {
          title: "系统供电",
          desc: "支持通用USB（Type-C）供电和外接电池包供电模式",
        },
        {
          title: "通信能力",
          desc: "EEE 802.11b/g/n网络连接标准，支持20MHz标准带宽和5MHz/10MHz窄带宽，提供最大72.2Mbit/s 物理层速率。Wi-Fi基带支持正交频分复用（OFDM）技术，并向下兼容直接序列扩频（DSSS）和补码键控（CCK）技术",
        },
      ],
      development: [
        {
          title: "Chinasoft_WIFI_IoT_物联网母板",
          img: require("@/assets/img/development/development1.png"),
          info: [
            "提供专用主板插槽",
            "提供多种功能板接口",
            "提供丰富的丝印接口说明，降低开发难度",
            "预留丰富的插槽、卡位、排针，提升开发自主性",
            "板载锂电池接口",
          ],
        },
        {
          title: "Chinasoft_WIFI_IoT_主控板",
          img: require("@/assets/img/development/development2.png"),
          info: [
            "板载海思 Hi3861V00 Wi-Fi SoC 芯片，提供WiFi组网能力",
            "提供开放操作系统 Huawei LiteOS，可实现多种鸿蒙/Hilink特性",
            "提供USB Type C 接口，可用于供电、调试和烧录",
          ],
        },
        {
          title: "Chinasoft_WIFI_IoT_Hi3861_ LCD显示板",
          img: require("@/assets/img/development/development3.png"),
          info: [
            "提供 1 个 OLED 显示屏，用户可以自定义显示内容",
            "提供 1 个按键，用户可以自定义具体功能",
          ],
        },
        {
          title: "Chinasoft_WIFI_IoT_照明板",
          img: require("@/assets/img/development/development4.png"),
          info: [
            "提供 1 个红灯、1 个黄灯、1 个绿灯，用户可以自定义各灯的开关",
            "提供 1 个按键，用户可以自定义具体功能",
            "提供 1 个蜂鸣器，用户可自定义开关",
          ],
        },
        {
          title: "Chinasoft_WIFI_IoT_Hi3861_人体感应板",
          img: require("@/assets/img/development/development5.png"),
          info: [
            "提供 1 个三色灯(红、绿、蓝，可混色)，用户可以自定义各灯的开关",
            "提供 1 个人体红外感应器，可以探测是否有人出现并上报结果",
            "提供 1 个光敏电阻，可以探测光照度的变化并上报结果",
          ],
        },
        {
          title: "Chinasoft_WIFI_IoT_Hi3861_可燃气体感应板",
          img: require("@/assets/img/development/development6.png"),
          info: [
            "提供 1 个温湿度感应器，可以探测环境的温湿度值并上报结果",
            "提供 1 个可燃气体传感器，可以探测环境中是否有可燃气体、烟雾，并上报结果",
            "提供 1 个蜂鸣器，用户可以自定义开关",
          ],
        },
        {
          title: "Chinasoft_WIFI_IoT_Hi3861_ NFC板",
          img: require("@/assets/img/development/development7.png"),
          info: ["提供 NFC 功能，模拟磁卡，可实现碰一碰功能"],
        },
        {
          title: "Chinasoft_WIFI_IoT_Hi3861_ JATAG板",
          img: require("@/assets/img/development/development8.png"),
          info: ["提供JTAG接口，方便用户下载和调试程序"],
        },
      ],
      company: null,
    };
  },
  computed: mapGetters(["userInfo"]),
  methods: {
    toShiXun(type) {
      const self = this;
      if (!this.userInfo) {
        this.$router.push({
          path: "/login",
          query: { redirect_uri: location.href },
        });
        return;
      }
      if (!this.company) {
        this.modal = this.$confirm({
          title: (h) => <p style="font-weight: 600;">温馨提示</p>,
          content: (h) => (
            <div style="color: rgba(0,0,0,0.65);font-size: 14px;">
              您还不是企业用户，可前往创建企业或者加入企业，成功后可进入实训平台进行体验！
            </div>
          ),
          okText: "加入企业",
          cancelText: "创建企业",
          icon: (h) => <a-icon theme="filled" type="exclamation-circle" />,
          closable: true,
          class: "experience-tips-box",
          onOk() {
            window.open(
              `${process.env.VUE_APP_ACCOUNT_PREFIX}/userLayout/userInfo/associationEnterprise`
            );
          },
          onCancel(e) {
            if(e.triggerCancel){
              return
            }
            self.modal.destroy();
            window.open(
              `${process.env.VUE_APP_ACCOUNT_PREFIX}/userLayout/userInfo/createCompany`
            );
          },
        });
        return;
      }
      const uri = `${process.env.VUE_APP_API_PORTAL_URL}/yuer/redirect?org_id=${this.company.id}&type=${type}`;
      console.log(uri);
      window.open(uri);
    },
    async loadCompany() {
      this.company = await getDefaultCompany();
    },
  },
  mounted() {
    if (this.userInfo) {
      this.loadCompany();
    }
  },
};
</script>

<style lang="scss" scoped>
.shi-xun-system {
  padding: 64px 0;
  background: url(../../assets/img/systembg.jpg) no-repeat;
  background-size: 100% 100%;
  > h2 {
    font-size: 32px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.85);
    line-height: 45px;
    text-align: center;
    margin-bottom: 16px;
  }
  > p {
    font-size: 18px;
    color: rgba(0, 0, 0, 0.65);
    line-height: 28px;
    text-align: center;
    margin-bottom: 48px;
  }
  > div {
    width: 1264px;
    margin: 0 auto;
    background: #fff;
    display: flex;
    .shi-xun-system-tag {
      width: 300px;
      min-height: 472px;
      border-right: 1px solid rgba(0, 0, 0, 0.2);
      padding: 32px 0;
      > p {
        height: 80px;
        line-height: 80px;
        text-align: center;
        font-size: 18px;
        color: rgba(0, 0, 0, 0.85);
        transition: all 0.3s;
        cursor: pointer;
        &.active {
          background: linear-gradient(90deg, #f61a0e 0%, #ea0b06 100%);
          color: #fff;
        }
      }
    }
    .system-content-box {
      flex: 1;
    }
    .content-box-change {
      > .shi-xun-system-content {
        padding: 32px 24px 24px;
        > .shi-xun-system-content-title {
          padding-bottom: 0;
          margin-bottom: 0;
          border-bottom: none;
        }
      }
    }
    .shi-xun-system-content {
      padding: 32px;
      .shi-xun-system-content-title {
        padding-bottom: 24px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.09);
        margin-bottom: 24px;
        > p {
          font-size: 18px;
          color: rgba(0, 0, 0, 0.82);
          line-height: 25px;
          margin-bottom: 16px;
        }
        > span {
          display: block;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.85);
          line-height: 20px;
        }
      }
      .shi-xun-system-equipment {
        display: flex;
        > div {
          flex: 1;
          > p {
            font-size: 16px;
            color: rgba(0, 0, 0, 0.82);
            line-height: 22px;
            margin-bottom: 28px;
          }
          > span {
            display: block;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.65);
            line-height: 20px;
            min-height: 120px;
          }
          > button {
            width: 136px;
            margin-top: 32px;
          }
        }
        > img {
          width: 300px;
          height: 168px;
          margin-left: 32px;
        }
      }
    }
    .system-dev-bottom {
      position: relative;
      /* &::after{
            content: '';
            position: absolute;
            bottom:0;
            left: 0;
            height: 24px;
            width: 100%;
            background: #FFFFFF;
          }*/
    }
    .shi-xun-system-dev {
      .xun-system-nav {
        height: 48px;
        display: flex;
        background: #f7f8fb;
        > div {
          flex: 1;
          cursor: pointer;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.85);
          line-height: 48px;
          text-align: center;
        }
        > .nav-active {
          border-bottom: 2px solid #ea0b06;
          color: #ea0b06;
        }
      }
      > .system-nav-one {
        padding: 24px;
      }
      .system-nav-two {
        height: 528px;
        overflow-y: auto;
        display: flex;
        flex-wrap: wrap;
        /* &::-webkit-scrollbar{
              display: none;
            }*/
        scrollbar-width: thin;
        scrollbar-color: #e6e6e6 #ffffff;
        position: relative;
        &::after {
          content: "";
          position: sticky;
          bottom: 0;
          left: 0;
          height: 24px;
          width: 100%;
          background: #ffffff;
        }
        /*滚动条里面轨道*/
        &::-webkit-scrollbar-track {
          background-color: #ffffff;
          margin: 0;
        }
        /*定义滚动条整体的样式*/
        &::-webkit-scrollbar {
          width: 6px;
          height: 264px;
        }
        /*滚动条的样式*/
        &::-webkit-scrollbar-thumb {
          border-radius: 3px;
          background: #e6e6e6;
        }

        > .development-item {
          padding: 24px;
          width: 318px;
          height: 298px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.09);

          position: relative;
          &:not(:nth-child(3n)) {
            border-right: 1px solid rgba(0, 0, 0, 0.09);
          }
          &:nth-child(3n) {
            width: 320px;
          }
          &:nth-last-of-type(-n + 2) {
            //border-right: none;
            border-bottom: none;
            height: 274px;
          }
          /* &:nth-last-of-type(-n+2)::after{
                content: '';
                position: absolute;
                width: 1px;
                height: 274px;
                background: rgba(0,0,0,0.09);
                right: 0;
                top: 0;
              }*/
          > .title {
            font-size: 14px;
            color: rgba(0, 0, 0, 0.82);
            line-height: 20px;
            margin-bottom: 16px;
          }
          > img {
            margin-bottom: 8px;
          }
          > .development-info {
            display: flex;
            margin-top: 8px;
            > img {
              display: block;
              width: 12px;
              height: 12px;
              margin-right: 6px;
              margin-top: 3px;
            }
            font-size: 12px;
            color: rgba(0, 0, 0, 0.65);
            line-height: 17px;
          }
        }
      }
      .shi-xun-system-dev-thead,
      .shi-xun-system-dev-tbody {
        display: flex;
        background: #dae2f1;
        > span {
          width: 200px;
          height: 48px;
          border-right: 1px solid #fff;
          text-align: center;
          font-size: 16px;
          color: rgba(0, 0, 0, 0.85);
          line-height: 48px;
        }
        > p {
          flex: 1;
          height: 48px;
          text-align: center;
          font-size: 16px;
          color: rgba(0, 0, 0, 0.85);
          line-height: 48px;
        }
      }
      .shi-xun-system-dev-tbody {
        background: #fff;
        border-left: 1px solid rgba(0, 0, 0, 0.09);
        border-right: 1px solid rgba(0, 0, 0, 0.09);
        border-bottom: 1px solid rgba(0, 0, 0, 0.09);
        > span {
          height: 72px;
          line-height: 72px;
        }
        > p {
          text-align: left;
          height: 72px;
          font-size: 12px;
          color: rgba(0, 0, 0, 0.65);
          line-height: 18px;
          padding: 10px 24px;
          display: flex;
          align-items: center;
        }
      }
    }
  }
}
</style>
